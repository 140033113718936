<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <BackButton :handler="goToMarketing" />
      </v-col>
    </v-row>
    <div class="md-card md-theme-default mt-8 rounded-5">
      <div class="md-card-content md-card-table">
        <div class="d-flex justify-space-between align-center ">
          <SvgIcon class="text-2xl font-semibold" text="Newsletter subscriptions" >
          </SvgIcon>
          <v-spacer/>

          <v-autocomplete
              v-model="searchParams.time_intervel"
              :items="timeDuration"
              class="q-autocomplete   shadow-0 m-r-3 mt-2"
              hide-details
              dense
              item-text="title"
              item-value="name"
              outlined
              placeholder="Time Period"
              style="max-width: 200px"
              @change="changeFxn"
              height="20"

          >
            <template v-slot:prepend-inner>
              <SvgIcon >
                <template v-slot:icon>
                  <EventIcon/>
                </template>
              </SvgIcon>
            </template>
          </v-autocomplete>


          <div v-if="flag" class="d-flex align-center gap-x-4 mt-2">
            <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                filled
                min-width="290px"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                    v-model="date1Formatted"
                    class="shadow-0 q-text-field"
                    flat
                    dense
                    hide-details
                    outlined
                    readonly
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="searchParams.start_date"
                  @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
            <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                min-width="290px"
                offset-y
                transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                    v-model="date2Formatted"
                    class="shadow-0 q-text-field"
                    flat
                    dense
                    hide-details
                    outlined
                    readonly
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="searchParams.end_date"
                  @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
            <v-btn class="mr-2 bg-blue text-white" height="40"  elevation="0" @click="search">
              Go
            </v-btn>
          </div>
          <v-btn
              v-if="checkExportPermission($modules.clients.customers.slug)"
              class="export-button mt-2"
              elevation="0"
              height="40"
              @click="exportData"
          >
            <SvgIcon text="Export" >
              <template v-slot:icon>
                <ExportIcon/>
              </template>
            </SvgIcon>
          </v-btn>
        </div>
        <div class="row mt-4">
          <div class="col-md-12">
            <div
                class="table-responsive"
            >
              <table class="table border-collapse text-center">
                <thead >
                <tr class="opacity-70 tr-neon tr-rounded text-center">
                  <th >
                    <div class="">Email</div>
                    <!-- <div class="search_column">
                      <v-autocomplete
                        solo
                        filled
                        label="Email"
                        v-model="searchParams.email"
                        item-value="email"
                        item-text="email"
                        :items="emails"
                        :loading="isLoading"
                        :search-input.sync="email"
                        @change="getFilterData"
                      ></v-autocomplete>
                    </div> -->
                  </th>

                  <th >
                    <div>Subscribed at</div>
                  </th>
                </tr>
                </thead>

                <tbody>
                <tr
                    class="md-table-row"
                    v-for="data in searchDatas"
                    :key="data.id"
                >
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      {{ data.email || "NA" }}
                    </div>
                  </td>

                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      {{ data.created_at | timeStamp }}
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-md-3"></div>
          <div class="col-md-6">
            <v-pagination v-model="page" :length="totalPages" class="new-pagination"></v-pagination>
          </div>
          <v-col class="d-flex align-center justify-end" cols="3">
            <div class="d-flex align-center justify-end text-sm gap-x-2">
                <span class="">
                  Result
                </span>
              <div style="width: 80px">
                <v-select
                    v-model="perPage"
                    :items="[10, 15, 25, 50]"
                    :menu-props="{ bottom: true, offsetY: true }"
                    class="q-autocomplete text-sm"
                    flat
                    hide-details
                    solo
                    @change="search"
                ></v-select>
              </div>
              <span>Per Page</span>
            </div>
          </v-col>
        </div>

      </div>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
import EventIcon from "@/assets/images/misc/calendar.svg";
import BackButton from "@/components/Common/BackButton.vue";
export default {
  components: {BackButton, EventIcon, ExportIcon, SvgIcon},
  props: {
    global: {
      type: Boolean,
      default: true,
    },
  },
  //   components: {
  //     CustomerModel,
  //     ConfirmModel,
  //   },
  data() {
    return {
      flag: false,
      currentPage: 0,
      pageSize: 10,
      emails: [],
      email: null,
      isLoading: false,
      searchDatas: [],
      searchFlag: false,
      searchParams: {
        start_date: moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      },
      page: 1,
      perPage: null,
      totalPages: 1,
      btnShow: false,
      timeDuration: [
        { name: "All", title: "All" },
        { name: "day", title: "This Day" },
        { name: "week", title: "This Week" },
        { name: "month", title: "This Month" },
        { name: "year", title: "This Year" },
        { name: "custom", title: "Custom Duration" },
      ],
      orderBy: "id",
      orderDir: "DESC",
    };
  },

  computed: {
    date1Formatted() {
      return moment(this.searchParams.start_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.end_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
  },
  mounted() {
    // if (this.$store.getters.getCountries.status == false) {
    //   this.$store.dispatch("loadCountries");
    // }
    // if (this.$store.getters.getTags.status == false) {
    //   this.$store.dispatch("loadTags");
    // }
    // if (!this.global) {
    this.search();
    // }
  },
  created() {
    if (typeof this.$route.params.id != "undefined") {
      this.searchParams.offline_user_id = this.$route.params.id;
    }

    setTimeout(() => {
      this.btnShow = true;
    }, 10);
  },
  watch: {
    page() {
      this.search();
    },
    // name(val) {
    //   if (val == "" || val == null) return;
    //   if (this.isLoading) return;
    //   this.filterSearch("name", val);
    // },
    // nationality(val) {
    //   if (val == "" || val == null) return;
    //   if (this.isLoading) return;
    //   this.filterSearch("nationality", val);
    // },
    // phone(val) {
    //   if (val == "" || val == null) return;
    //   if (this.isLoading) return;
    //   this.filterSearch("mobile", val);
    // },
    email(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("email", val);
    },
    // tag(val) {
    //   if (val == "" || val == null) return;
    //   if (this.isLoading) return;
    //   this.filterSearch("tag", val);
    // },
    // cardid(val) {
    //   this.searchMember(val);
    // },
  },
  methods: {
    goToMarketing() {
      this.$router.push({name: "Marketing"}, () => {
      });
    },
    sortColumn(type) {
      if (type == this.orderBy) {
        this.orderDir = this.orderDir == "ASC" ? "DESC" : "ASC";
      } else {
        this.orderDir = "DESC";
      }
      this.orderBy = type;
      this.search();
    },
    resetCustomer() {
      delete this.searchParams.offline_user_id;
      this.$router.push(
        {
          name: "Customers",
        },
        () => {}
      );
      this.search();
    },
    filterSearch(type, val) {
      if (val == "All") return;
      this.isLoading = true;
      this.$http
        .get("venues/customers/filters?field=" + type + "&search=" + val)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.isLoading = false;
            // if (type == "name") {
            //   this.names = response.data.data;
            //   this.names.unshift(val);
            //   this.names.unshift("All");
            // } else if (type == "mobile") {
            //   this.phones = response.data.data;
            //   this.phones.unshift(val);
            //   this.phones.unshift("All");
            // } else if (type == "nationality") {
            //   this.countries = response.data.data;
            //   this.countries.unshift(val);
            //   this.countries.unshift("All");
            // } else
            if (type == "email") {
              this.emails = response.data.data;
              this.emails.unshift(val);
              this.emails.unshift("All");
            }
            // else if (type == "tag") {
            //   this.tags = response.data.data;
            //   this.tags.unshift(val);
            //   this.tags.unshift("All");
            // }
          }
        })

        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFilterData() {
      this.page = 1;
      this.search();
    },
    ageOkClick() {
      if (this.fromAge != null && this.toAge != null) {
        this.searchParams.from_age = this.fromAge;
        this.searchParams.to_age = this.toAge;
        let customAgeRange = this.fromAge + "-" + this.toAge;
        this.AgeRange.push(customAgeRange);
        this.searchParams.age = customAgeRange;
        this.toAge = null;
        this.fromAge = null;
        this.$refs.ageRef.blur();
        this.search();
      }
    },
    ageChange() {
      if (this.searchParams.age != "All") {
        let data = this.searchParams.age.split("-");
        this.searchParams.from_age = data[0];
        this.searchParams.to_age = data[1];
        this.search();
      } else {
        this.allAge();
      }
    },
    allAge() {
      delete this.searchParams.from_age;
      delete this.searchParams.to_age;
      delete this.searchParams.age;
      this.search();
    },
    search() {
      let url = "";
      url =
        "&status=all" +
        `&order_by_custom=${this.orderBy}&sort_order=${this.orderDir}`;

      if (
        this.searchParams.time_intervel != "All" &&
        this.searchParams.time_intervel != null
      ) {
        // this.searchParams.end_date = moment().format("YYYY-MM-DD");
        if (this.searchParams.time_intervel == "custom") {
          this.flag = true;
        } else if (this.searchParams.time_intervel == "day") {
          this.searchParams.start_date = moment().format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "week") {
          this.searchParams.start_date = moment()
            .startOf("week")
            .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "month") {
          this.searchParams.start_date = moment()
            .startOf("month")
            .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "year") {
          this.searchParams.start_date = moment()
            .startOf("year")
            .format("YYYY-MM-DD");
        }
        url += "&end_date=" + this.searchParams.end_date;
        url += "&start_date=" + this.searchParams.start_date;
      }

      if (this.searchParams.name == "All") {
        delete this.searchParams.name;
      } else if (typeof this.searchParams.name != "undefined") {
        url += "&name=" + this.searchParams.name;
      }
      if (this.searchParams.email == "All") {
        delete this.searchParams.email;
      } else if (typeof this.searchParams.email != "undefined") {
        url += "&email=" + this.searchParams.email;
      }
      if (
        typeof this.searchParams.type != "undefined" &&
        this.searchParams.type == "m"
      ) {
        url += "&membership_type=" + this.searchParams.type;
      } else if (
        typeof this.searchParams.type != "undefined" &&
        this.searchParams.type == "n"
      ) {
        url += "&membership_type=" + this.searchParams.type;
      } else if (
        typeof this.searchParams.type != "undefined" &&
        this.searchParams.type != "all"
      ) {
        url += "&customer_type_id=" + this.searchParams.type;
      }

      //   if (this.searchParams.member_card_num == null) {
      //     delete this.searchParams.member_card_num;
      //   } else if (typeof this.searchParams.member_card_num != "undefined") {
      //     url += "&member_card_num=" + this.searchParams.member_card_num;
      //   }
      //   if (this.searchParams.mobile == "All") {
      //     delete this.searchParams.mobile;
      //   } else if (typeof this.searchParams.mobile != "undefined") {
      //     url += "&mobile=" + this.searchParams.mobile;
      //   }
      //   if (this.searchParams.gender == "All") {
      //     delete this.searchParams.gender;
      //   } else if (typeof this.searchParams.gender != "undefined") {
      //     url += "&gender=" + this.searchParams.gender;
      //   }
      //   if (this.searchParams.nationality == "All") {
      //     delete this.searchParams.nationality;
      //   } else if (typeof this.searchParams.nationality != "undefined") {
      //     url += "&nationality=" + this.searchParams.nationality;
      //   }
      //   if (typeof this.searchParams.offline_user_id != "undefined") {
      //     url += "&offline_user_id=" + this.searchParams.offline_user_id;
      //   }
      //   if (
      //     typeof this.searchParams.from_age != "undefined" &&
      //     typeof this.searchParams.to_age != "undefined"
      //   ) {
      //     url +=
      //       "&from_age=" +
      //       this.searchParams.from_age +
      //       "&to_age=" +
      //       this.searchParams.to_age;
      //   }
      //   if (this.searchParams.customer_type == "All") {
      //     delete this.searchParams.customer_type;
      //   } else if (typeof this.searchParams.customer_type != "undefined") {
      //     url +=
      //       "&customer_type=" +
      //       (this.searchParams.customer_type == "Individual" ? "I" : "C");
      //   }
      //   if (this.searchParams.tag == "All") {
      //     delete this.searchParams.tag;
      //   } else if (typeof this.searchParams.tag != "undefined") {
      //     url += "&tag=" + this.searchParams.tag;
      //   }
      //   if (this.eventId > 0) {
      //     url += "&event_id=" + this.eventId;
      //   }
      this.showLoader("Loading Customer");
      this.$http
        .get(
          "venues/marketing/newsletter?page=" +
            this.page +
            "&per_page=" +
            (this.perPage != null ? this.perPage : 10) +
            url
        )
        .then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status) {
            this.searchDatas = response.data.data;
            this.totalPages = response.data.total_pages;
            // if (typeof this.searchParams.offline_user_id != "undefined") {
            //   this.showUserModel(this.searchParams.offline_user_id);
            // }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    // showUserModel(userId) {
    //   this.userModel.userID = parseInt(userId);
    //   this.userModel.type = "details";
    // },
    // historyLog(userId) {
    //   this.userModel.type = "history";
    //   this.userModel.userID = parseInt(userId);
    // },
    // editCustomer(userId) {
    //   this.userModel.type = "edit";
    //   this.userModel.userID = parseInt(userId);
    // },
    searchMember(val) {
      if (
        typeof this.searchParams.member_card_num != "undefined" &&
        this.searchParams.member_card_num == val
      )
        return;
      if (val == "" || val == null) return;
      if (this.isMemberLoading) return;
      this.isMemberLoading = true;
      this.$http
        .get("memberships/members/search?term=" + val)
        .then((response) => {
          if (response.status == 200) {
            this.isMemberLoading = false;
            this.memberEntries = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    exportData() {
      let url = "";
      url = "&status=all";
      if (this.searchParams.name == "All") {
        this.searchParams.name = null;
      } else if (typeof this.searchParams.name != "undefined") {
        url += "&name=" + this.searchParams.name;
      }
      if (this.searchParams.email == "All") {
        delete this.searchParams.email;
      } else if (typeof this.searchParams.email != "undefined") {
        url += "&email=" + this.searchParams.email;
      }
      if (typeof this.searchParams.type != "undefined") {
        url += "&type=" + this.searchParams.type;
      }
      if (this.searchParams.member_card_num == null) {
        delete this.searchParams.member_card_num;
      } else if (typeof this.searchParams.member_card_num != "undefined") {
        url += "&member_card_num=" + this.searchParams.member_card_num;
      }
      if (this.searchParams.mobile == "All") {
        delete this.searchParams.mobile;
      } else if (typeof this.searchParams.mobile != "undefined") {
        url += "&mobile=" + this.searchParams.mobile;
      }
      if (this.searchParams.gender == "All") {
        delete this.searchParams.gender;
      } else if (typeof this.searchParams.gender != "undefined") {
        url += "&gender=" + this.searchParams.gender;
      }
      if (this.searchParams.nationality == "All") {
        delete this.searchParams.nationality;
      } else if (typeof this.searchParams.nationality != "undefined") {
        url += "&nationality=" + this.searchParams.nationality;
      }
      if (typeof this.searchParams.offline_user_id != "undefined") {
        url += "&offline_user_id=" + this.searchParams.offline_user_id;
      }
      if (this.eventId > 0) {
        url += "&event_id=" + this.eventId;
      }
      if (
        typeof this.searchParams.from_age != "undefined" &&
        typeof this.searchParams.to_age != "undefined"
      ) {
        url +=
          "&from_age=" +
          this.searchParams.from_age +
          "&to_age=" +
          this.searchParams.to_age;
      }
      if (this.searchParams.customer_type == "All") {
        delete this.searchParams.customer_type;
      } else if (typeof this.searchParams.customer_type != "undefined") {
        url +=
          "&customer_type=" +
          (this.searchParams.customer_type == "Individual" ? "I" : "C");
      }
      if (url != "") {
        url = "?" + url.substring(1);
      }
      this.$http
        .get(`venues/marketing/newsletter/export` + url, {
          responseType: "blob",
        })
        .then((response) => {
          if (response.status == 200) {
            this.downloadFile(response, "Newsletter_subscription_Export_");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    checkTableColumnVisibility(column) {
      let index = this.columns.findIndex((item) => item == column);
      if (index == -1) {
        return false;
      }
      return true;
    },
    deactivateConfirmModal(data) {
      let type = data.status_id == 1 ? "Deactivate" : "Activate";
      this.confirmModel = {
        id: data.id,
        title: `Do you want to ${type} this customer?`,
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: type,
      };
    },
    confirmActions(data) {
      if (data.type == "Deactivate" || data.type == "Activate") {
        this.removeCustomer(data);
      }
      this.confirmModel.id = null;
    },
    removeCustomer(data) {
      console.log("customer_id", data.id);
      this.showLoader();
      this.$http
        .delete(`venues/customers/${data.id}`)
        .then((res) => {
          if (res.status == 200) {
            this.showSuccess(`Customer ${data.type} Successfully.`);
            this.hideLoader();
            this.search();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },

    changeFxn() {
      if (this.searchParams.time_intervel == "custom") {
        this.searchParams.start_date = moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD");
        this.searchParams.end_date = moment().format("YYYY-MM-DD");
        this.flag = true;
      } else {
        this.flag = false;
        this.search();
      }
    },
  },
};
</script>

<style scoped>
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
tbody tr:hover {
  transform: scale(1) !important;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -moz-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  z-index: 1;
}
</style>
